.session-view {
  height: 100%;
  overflow: hidden;

  .session-layout {
    height: 100%;
    overflow: hidden;
  }

  .session-list {
    color: #333;
  }
  .session-item {
    .desc {
      margin-top: 0;
      height: 32px;
      line-height: 16px;
      width: 90%;
    }
    .badge {
      margin-top: 0;
    }
    .btn-delete {
      right: 20px;
    }
  }
  .btn-delete:hover path {
    fill: #00b5ce;
  }
}
