.session-toolbar {
  background: #fff;
  border-bottom: 1px solid #e6e7eb;
  height: 50px;
  line-height: 50px;
  padding: 0 21px;
}

.session-toolbar__title {
  display: inline-block;
  font-size: 18px;
  color: #757575;
  margin-left: 8px;
  font-family: 'HelveticaNeue-Medium';
  line-height: 20px;
}

.session-toolbar__icon {
  display: inline-block;
  top: 3px;
  position: relative;
  line-height: normal;
  color: #757575;
}
