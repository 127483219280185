.im-button {
  display: inline-block;
  padding: 8px 21px;
  font-size: 12px;
  background: #dadada;
  border: 1px solid #dadada;
  text-transform: uppercase;
  color: #fff;
  line-height: 12px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

.im-button--mod-primary:not(:disabled) {
  background: #1a9cb7;
  border-color: #1a9cb7;
}

.im-button--mod-clear {
  border: none;
  background: transparent;
  padding: 0;
}
