.im-emotions {
  display: inline-block;
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .simple-tab {
    padding: 0;
    border-top: 1px solid #ddd;
    .tab-item {
      max-width: 50px;
      .ripple {
        height: 100%;
        width: 100%;
        background: transparent;
        transition: all 0.5s;
        padding-top: 6px;
        box-sizing: border-box;
      }
      &.selected .ripple {
        background: rgba(0, 0, 0, 0.08);
      }
      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  .emoji-list,
  .scroll-content,
  .emoji-item {
    box-sizing: border-box;
  }

  .emoji-list {
    height: 260px;
    overflow: hidden;

    .scroll-content {
      padding-top: 5px;
      padding-bottom: 15px;
    }

    .emoji-item {
      display: inline-block;
      width: 25%;
      padding: 5px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }

    .emoji-item-pic {
      width: 70px;
      height: 70px;
    }

    .emoji-item-label {
      display: block;
      color: #757575;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
