$height: 8px;
$right: 10px;

.balloon {
  display: inline-block;
  position: relative;
}

.balloon__wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 3px;
  word-wrap: break-word;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  padding: 0;
  bottom: calc(100% + 10px);
  right: calc(50% - #{$right} - #{$height} / 2);
  width: 306px;

  &:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -$height;
    right: $right;
    border-top: $height solid #fff;
    border-left: $height solid transparent;
    border-right: $height solid transparent;
  }
}

.balloon--hidden {
  .balloon__wrapper {
    display: none;
  }
}
