.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-preview__footer {
  flex-shrink: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
  padding: 0 18px;
  flex-grow: 0;
}

.image-preview__content {
  flex-grow: 1;
  margin: 20px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview--mod-is-uploaded .image-preview__content {
  margin-top: 0px;
  align-items: center;
  margin-bottom: 46px;
}

.image-preview__button {
  margin-left: 8px;
}

.image-preview__img {
  max-width: 100%;
  max-height: 385px;
  display: block;
}

.image-preview__overlay {
  display: flex;
  flex-direction: column;
}

.image-preview__img-wrap {
  display: inline-block;
}

.image-preview__progress {
  height: 3px;
  margin-top: 5px;
  width: 100%;
}

.image-preview__progress-inner {
  background: #1a9cb7;
  height: 3px;
  opacity: 0.5;
  width: 0%;
  transition: width 0.1s ease;
}

.image-preview__header {
  height: 46px;
  padding: 0 16px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.image-preview__close {
  margin-left: 25px;
  cursor: pointer;
}

.image-preview__im-button {
  margin-left: 10px;
}
