.im-notification {
  position: absolute;
  top: 54px;
  left: 2px;
  background: #fee3e1;
  border: 1px solid #f44336;
  width: 382px;
  height: 42px;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
  z-index: 2;
}

.im-notification__icon {
  margin-right: 6px;
}

.im-notification__text {
  font-size: 14px;
  color: #f44336;
  letter-spacing: 0;
  line-height: 18px;
}
