.skeleton-message {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &__content {
    width: 250px;
    display: flex;
    align-items: flex-end;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  &__line {
    height: 10px;
    margin-bottom: 3px;
    width: 210px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__date {
    width: 40px;
    margin-right: 5px;
  }

  &_type_seller {
    flex-direction: row-reverse;

    .skeleton-message {
      &__content {
        flex-direction: row-reverse;
      }

      &__avatar {
        margin-left: 0px;
        margin-right: 10px;
      }

      &__date {
        margin-right: 0px;
        margin-left: 5px;
      }
    }
  }

  &_type_session {
    flex-direction: row-reverse;
    padding: 15px 10px 19px 12px;
    border-bottom: 1px solid #e6e7eb;
    height: 96px;
    box-sizing: border-box;
    margin: 0px;

    .skeleton-message {
      &__content {
        flex-direction: row-reverse;
        width: 202px;
      }

      &__avatar {
        margin-left: 0px;
        margin-right: 10px;
      }

      &__date {
        margin-right: 0px;
        margin-left: 5px;
        align-self: flex-start;
      }

      &__line {
        width: 165px;
        height: 25px;
      }
    }
  }
}

.skeleton-message + .skeleton-message {
  margin-top: 30px;

  &_type_session {
    margin: 0px;
  }
}
