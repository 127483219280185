.left-sidebar {
  width: 272px;
  height: 100%;
  border-right: 1px solid #e6e7eb;
  // transition: all 0.5s;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .blank {
    height: 100%;
    background-color: #eff0f5;
  }
}

.login-box {
  text-align: center;
  font-size: 16px;
  color: #4c8296;
  & > span {
    cursor: pointer;
  }
}
.im-app .login {
  display: flex;
  height: 100%;

  .message-view-title {
    text-transform: capitalize;
  }

  .message-view-content {
    flex: none;
    .empty-box {
      -webkit-transform: none;
      transform: none;
      margin: 27% auto 20px auto;
    }
  }
}
