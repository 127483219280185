.message-view-title {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e7eb;
  height: 50px;
  padding: 0 16px;
  flex-shrink: 0;
}

.message-view-title__content {
  font-size: 18px;
  color: #424242;
  font-family: 'HelveticaNeue-Medium';
  text-align: left;
  line-height: 20px;
}

.message-view-title__btn-action-close {
  cursor: pointer;
  color: #9e9e9e;
  font-size: 18px;
  background: transparent;
  border: none;
}
