.im-smart-card {
  position: absolute;
  z-index: 2;
  margin: 10px;
  width: calc(100% - 20px);
  top: 51px;
  padding-top: 16px;
  overflow: hidden;
  box-sizing: border-box;
  height: 144px;
  background-image: linear-gradient(217deg, #ff8763 0%, #ff330c 100%);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  border-radius: 13px;

  .content {
    flex: 1;
    display: flex;
    padding-bottom: 10px;
  }

  .image {
    width: 60px;
    height: 60px;
    margin: 0 12px;
    margin-top: 4px;
    border-radius: 2px;
  }

  .btn {
    width: 120px;
    height: 28px;
    line-height: 28px;
    background: #0898ff;
    display: block;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
  }

  .footer {
    height: 52px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
  }

  .desc {
    flex: 1;
    padding-right: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 14px;
    color: white;
    line-height: 18px;
    min-height: 36px;
    max-height: 36px;
    flex: 1;
  }
  .price {
    font-size: 14px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }

  .discount-row {
    display: flex;
    overflow: hidden;
    .original-price {
      flex: 1;
      text-decoration: line-through;
    }
    .discount-text {
      min-width: 40px;
    }
  }
  .original-price,
  .discount-text {
    font-size: 12px;
    color: #eee;
  }
}
