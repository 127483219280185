.skeleton-dummy {
  height: 10px;
  width: 100%;
  -webkit-animation: skeleton-animation 1.2s ease-in-out infinite;
  animation: skeleton-animation 1.2s ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-repeat: no-repeat;
  background-size: 200px 100%;
}

@-webkit-keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
