.message-view-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #F9F9F9;

  .on-board-warning {
    position: absolute;
    top: 0;
    z-index: 99;
    font-size: 18px;
    color: #333333;
    padding: 16px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      float: left;
    }
  }

  &--mod-loading {
    overflow: hidden;
    background: white;
    display: flex;
    flex-direction: column;
  }

  &__spinner {
    text-align: center;
  }
  .message-box {
    // position: absolute;
    // width: 100%;
    height: 100%;

    .message-row {
      padding-left: 6px;
      padding-right: 12px;
    }
    .message-row.user-type-2 {
      flex-direction: row;
      .read-type {
        display: none;
      }
    }
    .message-row.user-type-1 {
      flex-direction: row-reverse;
    }
  }
  .empty-box {
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 285px;
    color: #757575;
    margin: 50% auto 0;
    font-size: 14px;
  }
  .empty-icon {
    margin-bottom: 35px;
    display: inline-block;
  }
  .lzd-message-item {
    .img-placeholder {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .card-img {
      width: 82px;
    }
  }
  // temp changes for proper display before message-list pub
  .emoji-face {
    cursor: text;
  }
  .emoji-img {
    vertical-align: bottom;
    img {
      vertical-align: bottom;
      height: 30px;
    }
  }
  .has-text .emoji-img img,
  .many-emoji .emoji-img img {
    height: 20px; // fit into text line-height
  }
  .hide-for-select { // for copy&paste selection
    position: absolute;
    opacity: 0;
    left: -9999px;
    top: -9999px;
  }
}

.skeleton-messages-list {
  background: white;
  z-index: 99;
}

.donwload-address {
  margin: 4px 0;

  a {
    font-size: 12px;
    font-weight: bold;
    color: #00b5e0;
    text-decoration: underline;
  }
}

// .text-two-lines {
//   position: relative;
//   display: block;
//   line-height: 1.2em;
//   height: 2.4em;
// }
// .text-two-lines:after {
//   content: " ";
//   text-align: right;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 70%;
//   height: 1.2em;
//   background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
// }
