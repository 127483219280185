.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  flex-direction: column;
  display: none;
}

.overlay--mod-show {
  display: flex;
}
