.window-confirm__dialog {
  position: relative;
  width: 388px;
  height: 186px;
  background: #fafafa;
  margin: 0 auto;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
  flex-direction: column;
}

.window-confirm__dialog-header {
  display: flex;
}

.window-confirm__dialog-title {
  flex-grow: 1;
  font-size: 18px;
  color: #000;
  margin: 24px 0 0 24px;
  display: inline-block;
}

.window-confirm__dialog-btn-close {
  margin: 16px 16px 0 0;
}

.window-confirm__dialog-content {
  flex-grow: 1;
  margin: 8px 24px;
  font-size: 14px;
  color: #757575;
  line-height: 18px;
}

.window-confirm__dialog-btn-ok__button {
  margin-left: 8px;
}

.window-confirm__dialog-footer {
  text-align: right;
  padding: 0 24px 24px;

  button + button {
    margin-left: 10px;
  }
}
