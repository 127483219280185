.message-view-field {
  display: flex;
  padding: 8px 10px;
  background: #f9f9f9;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #ebecf0;
  box-sizing: border-box;
}
.message-view-field__input-additional {
  margin: 0 10px;
  display: inline-block;
  cursor: pointer;
}
.message-view-field__input-block {
  flex-grow: 1;
  display: flex;
  background: #fff;
  padding: 2px 6px 2px 22px;
  align-items: center;
  border: 1px solid #cdced2;
  border-radius: 20px;
  position: relative;
  margin-left: 11px;

  .iconfont {
    color: #999;
    cursor: pointer;
  }

  .emoji-face {
    height: 30px;
  }

  .toast-tip-message {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -20px);
    background-color: #1a9cb7;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  }
}
.message-view-field__textarea {
  border: none;
  line-height: 20px;
  font-size: 14px;
  color: #212121;
  outline: none;
  padding: 0;
  resize: none;
  flex-grow: 1;
  margin-right: -15px;
}

.message-view-field__send {
  margin-left: 10px;
  color: #9e9e9e;
  cursor: pointer;
  border: none;
  background: transparent;
}

.message-view-field__scroll-overlay {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 20px;
  background: #fff;
  box-sizing: border-box;
  top: 0;
  right: 22px;
}

.message-view-field__upload {
  cursor: pointer;
}
.balloon__trigger {
  display: flex;
}
.message-fileds__autosize {
  border: none;
  line-height: 20px;
  font-size: 14px;
  color: #212121;
  outline: none;
  padding: 0 3px 0 4px;
  margin-right: 4px;
  resize: none;
  flex-grow: 1;
}
.icon-plane.enable path {
  fill: #00b6ce;
}
