$app-width: 740px;
$app-height: 500px;
$button-width: 160px;
$button-height: 40px;

.im-app {
  font-family: 'Roboto', sans-serif;
  height: $app-height;
  width: $app-width;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 0 7px 0 #999;
  // transition: all 0.2s;
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 1000000;
  transform-origin: right bottom;

  &.opening {
    transform: scale($button-width / $app-width, $button-height/$app-height);
  }
  &.opened {
    transform: scale(1, 1);
    transition: all 0.4s cubic-bezier(0.2, 1, 0.4, 1);
  }
  &.closing {
    transform: scale($app-width / $button-width, $app-height / $button-height);
  }
  &.closed {
    transform: scale(1, 1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &.im-app--mod-minimize {
    width: $button-width;
    height: $button-height;
    border-radius: 2px 2px 0 0;
    box-shadow: 0 2px 4px 0;
    color: rgba(0, 62, 82, 1);
    cursor: pointer;

    button {
      width: 160px; // used fixed value void resize when clicking
    }
  }
}

.im-app .cont-maximize {
  display: flex;
  height: 100%;
}

.im-app__cont-minimize {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: transparent;
  border: none;
  height: 100%;
  cursor: pointer;
  // transition: all 0.1s;

  .button-mod-box {
    display: flex;
    align-items: center;
    align-content: flex-start;
    min-width: 135px;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  path {
    fill: #044254;
  }
  .im-app__minimize-title {
    margin-left: 8px;
    display: inline-block;
    font-size: 18px;
    color: #044254;
  }

  &:active {
    path {
      fill: #ffffff;
    }
    .im-app__minimize-title {
      color: #ffffff;
    }
    background: #044254;
  }
}

.im-app__minimize-badge {
  box-sizing: border-box;
  min-width: 16px;
  color: #fff;
  margin-left: 5px;
  display: inline-block;
  height: 20px;
  min-width: 20px;
  border-radius: 20px;
  background: #d43;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 1px 6px;
}

.im-app__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);

  &.im-app__overlay--hidden {
    display: none;
  }
}

:focus {
  outline: none;
}

.left-sidebar {
  width: 272px;
  height: 100%;
  border-right: 1px solid #e6e7eb;
  // transition: all 0.5s;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .blank {
    height: 100%;
    background-color: #eff0f5;
  }
}
.login-box {
  text-align: center;
  font-size: 16px;
  color: #4c8296;
  & > span {
    cursor: pointer;
  }
}
.clickable {
  cursor: pointer;
}
