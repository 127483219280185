@import '~@alife/lazada-message-list/lib/index.scss';
@import '~@alife/session-list/lib/index.scss';

@import './container/app/index.scss';

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

.user-type-2 {
  .message-withdraw {
    display: none !important;
  }
}
